@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import "colour.css";

/* @import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Poppins:ital,wght@0,100;0,200;0,800;1,1001,300&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
* {
    font-family: 'Quicksand', sans-serif;
}

body {
    overflow-x: hidden;
    background: #EBEDEF !important;
    /* background: whitesmoke; */
}

.bg-g1-gold {
    background: #ff8200 !important;
}
.g1-gold {
    color: #ff8200 !important;
}

.bg-g1-pay {
    background: #f05587 !important;
}

a.active {
    background-color: #c4d7ed;
    border-left: 6px solid #002c6c;
}

.text-link {
    text-decoration: none;
}
.dashline{
    border: dashed 1px gray;
}

input[type="radio"]{
    cursor: pointer;
}
.create_gtn-card .form-check{
    transition: all 0.4s ease-in-out;
}
.create_gtn-card .form-check:hover{
    font-weight: bold;
}